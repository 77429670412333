body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
header.MuiPaper-root{
  background: white;
  color: #787878;
}

.MuiSelect-filled.MuiSelect-filled {
  padding-right: 32px;
  min-width: 100px;
}

#mediaTypeId, #mediaSourceId{
  min-width: 212px;
}
#brands.RaSelectArrayInput-root-49, #products.RaSelectArrayInput-root-49{
  width: 100%;
}
.custom-modal {
  position: fixed; /* Stay in place */
  z-index: 9999999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.custom-modal-button-color {
  color: #f44336 !important;
}
.MuiButton-textSizeSmall{
  padding: 4px 14px !important;
}
.RaAutocompleteArrayInput-container-52{
  display: flex !important;
  margin: 6px 0 !important;
}

